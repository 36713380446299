const loginModuleRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/BaseLogin.vue'),
    meta: {
      layout: () => import('./layouts/BaseLoginLayout.vue'),
    },
  },
  {
    path: '/alza/login',
    name: 'alza-login',
    component: () => import('./views/AlzaLogin.vue'),
    meta: {
      layout: () => import('./layouts/AlzaLoginLayout.vue'),
    },
  },
  {
    path: '/aures/login',
    name: 'aures-login',
    component: () => import('./views/AuresLogin.vue'),
    meta: {
      layout: () => import('./layouts/AuresLoginLayout.vue'),
    },
  },
  {
    path: '/dpd/login',
    name: 'dpd-login',
    component: () => import('./views/DpdLogin.vue'),
    meta: {
      layout: () => import('./layouts/DpdLoginLayout.vue'),
    },
  },
  {
    path: '/hochtief/login',
    name: 'hochtief-login',
    component: () => import('./views/HochtiefLogin.vue'),
    meta: {
      layout: () => import('./layouts/HochtiefLoginLayout.vue'),
    },
  },
  {
    path: '/hochtief/login/classic',
    name: 'hochtief-login-classic',
    component: () => import('./views/HochtiefLoginClassic.vue'),
    meta: {
      layout: () => import('./layouts/HochtiefLoginLayout.vue'),
    },
  },
  {
    path: '/innogy/login',
    name: 'innogy-login',
    component: () => import('./views/InnogyLogin.vue'),
    meta: {
      layout: () => import('./layouts/InnogyLoginLayout.vue'),
    },
  },
  {
    path: '/jrd/login',
    name: 'jrd-login',
    component: () => import('./views/JrdLogin.vue'),
    meta: {
      layout: () => import('./layouts/JrdLoginLayout.vue'),
    },
  },
  {
    path: '/lasvit/login',
    name: 'lasvit-login',
    component: () => import('./views/LasvitLogin.vue'),
    meta: {
      layout: () => import('./layouts/LasvitLoginLayout.vue'),
    },
  },
  {
    path: '/magna/login',
    name: 'magna-login',
    component: () => import('./views/MagnaLogin.vue'),
    meta: {
      layout: () => import('./layouts/MagnaLoginLayout.vue'),
    },
  },
  {
    path: '/pns/login',
    name: 'pns-login',
    component: () => import('./views/PnsLogin.vue'),
    meta: {
      layout: () => import('./layouts/PnsLoginLayout.vue'),
    },
  },
  {
    path: '/satpo/login',
    name: 'satpo-login',
    component: () => import('./views/SatpoLogin.vue'),
    meta: {
      layout: () => import('./layouts/SatpoLoginLayout.vue'),
    },
  },
  {
    path: '/synthon/login',
    name: 'synthon-login',
    component: () => import('./views/SynthonLogin.vue'),
    meta: {
      layout: () => import('./layouts/SynthonLoginLayout.vue'),
    },
  },
  {
    path: '/terra-bohemia/login',
    name: 'terra-bohemia-login',
    component: () => import('./views/TerraBohemiaLogin.vue'),
    meta: {
      layout: () => import('./layouts/TerraBohemiaLoginLayout.vue'),
    },
  },
];

loginModuleRoutes.forEach((route) => {
  route.meta = {
    ...route.meta,
    authRequired: false,
    fullLayout: true,
  };
});

export default loginModuleRoutes;
